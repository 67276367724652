<template>
  <h2>經紀費管理</h2>

  <!-- 試算結果預覽 -->
  <div v-if="calced">
    <div class="text-end mb-5">
      <a href="" @click.prevent="calcCancel()">返回上一頁</a>
    </div>

    <div class="mb-4 text-danger">
      <div class="row align-items-end">
        <div class="col">
          * 請勿多個視窗同時在此畫面點擊 “確認發送G幣” 之按鈕
        </div>
        <div class="col text-end">
          <button
            class="btn btn-success mb-1"
            type="button"
            v-on:click="onexport"
          >
            <i class="bi bi-file-earmark-spreadsheet"></i> Excel匯出
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-hover" id="calcedTable">
        <thead class="bg-light">
          <tr>
            <td rowspan="2">單號</td>
            <td rowspan="2">會員身份</td>
            <td rowspan="2">服務店家</td>
            <td rowspan="2">編號藝名</td>
            <td rowspan="2">會員帳號 (暱稱)</td>
            <td rowspan="2">平均台數</td>
            <td rowspan="2">上台數</td>
            <td rowspan="2">上班天數</td>
            <td rowspan="2">薪資金額</td>
            <td rowspan="2">經紀費用</td>
            <td rowspan="2">公司經紀費金額</td>
            <td rowspan="2">會員分潤金額</td>
            <!-- <td colspan="3">上一線</td>
            <td colspan="3">上二線</td>
            <td colspan="3">上三線</td> -->
            <td colspan="9">上線會員帳號</td>
          </tr>
          <tr>
            <td>會員</td>
            <td>％</td>
            <td>上一線</td>
            <td>會員</td>
            <td>％</td>
            <td>上二線</td>
            <td>會員</td>
            <td>％</td>
            <td>上三線</td>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(d, key, index) in brokerageFeesResultMsg" :key="index">
            <td class="wl">{{ String(d.no) }}</td>
            <td class="wm">
              <span v-if="d.agent === true">經紀人</span>
              <span v-else-if="d.role === 'agentCompanyA'">合作經紀公司 A</span>
              <span v-else-if="d.role === 'agentCompanyB'">合作經紀公司 B</span>
              <span v-else>一般</span>
            </td>
            <td class="wl">{{ d.store }}</td>
            <td class="wl">{{ d.numberStageName }}</td>
            <td class="wl">{{ d.member_id }} ({{ d.name }})</td>
            <td class="wl">
              {{
                (
                  Math.floor((d.hoursOnStage / d.daysAtWork) * 100) / 100
                ).toFixed(2)
              }}
            </td>
            <td class="wm">{{ d.hoursOnStage }}</td>
            <td class="wm">{{ d.daysAtWork }}</td>
            <td class="wm">{{ d.salary }}</td>
            <td class="wm">
              <div class="badge bg-warning">$ {{ d.memberFees }}</div>
            </td>
            <td class="wm">
              <div class="badge bg-warning">
                $ {{ d.memberFees - d.subtotalBonus }}
              </div>
            </td>
            <td class="wm">
              <div class="badge bg-warning">$ {{ d.subtotalBonus }}</div>
            </td>
            <td class="wm">
              <div v-if="d.top1.name">
                <div>{{ d.top1.member_id }} ({{ d.top1.name }})</div>
                <div
                  class="badge bg-secondary"
                  v-if="d.top1.role != 'generally'"
                >
                  <div v-if="d.top1.role === 'agent'">經紀人分潤</div>
                  <div v-else-if="d.top1.role === 'agentCompanyA'">
                    合作經紀公司A分潤
                  </div>
                  <div v-else-if="d.top1.role === 'agentCompanyB'">
                    合作經紀公司B分潤
                  </div>
                </div>
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div
                class="badge bg-info"
                v-if="d.top1.rate || d.top1.rate === 0"
              >
                {{ d.top1.rate }}％
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div
                class="badge bg-warning"
                v-if="d.top1.setBonus || d.top1.setBonus === 0"
              >
                $ {{ d.top1.setBonus }}
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div v-if="d.top2.name">
                {{ d.top2.member_id }} ({{ d.top2.name }})
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div
                class="badge bg-info"
                v-if="d.top2.rate || d.top2.rate === 0"
              >
                {{ d.top2.rate }}％
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div
                class="badge bg-warning"
                v-if="d.top2.setBonus || d.top2.setBonus === 0"
              >
                $ {{ d.top2.setBonus }}
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div v-if="d.top3.name">
                {{ d.top3.member_id }} ({{ d.top3.name }})
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div
                class="badge bg-info"
                v-if="d.top3.rate || d.top3.rate === 0"
              >
                {{ d.top3.rate }}％
              </div>
              <div v-else>...</div>
            </td>
            <td class="wm">
              <div
                class="badge bg-warning"
                v-if="d.top3.setBonus || d.top3.setBonus === 0"
              >
                $ {{ d.top3.setBonus }}
              </div>
              <div v-else>...</div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <b>總單量：{{ calcedCount }} 筆</b>
            </td>
            <td>
              <b>(真實)人數：{{ allCalcdTotal.allMemberIdCount }} 人</b>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div>總計：{{ allCalcdTotal.salaryTotal }}</div>
            </td>
            <td>
              <div>總計：{{ allCalcdTotal.allMemberFeesTotal }}</div>
            </td>
            <td>
              <div>
                總計：{{ allCalcdTotal.allMemberFeesTotal - calcedTotal }}
              </div>
            </td>
            <td>
              <div>總計：{{ calcedTotal }}</div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div>總計：{{ allCalcdTotal.top1SetBonusTotal }}</div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div>總計：{{ allCalcdTotal.top2SetBonusTotal }}</div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div>總計：{{ allCalcdTotal.top3SetBonusTotal }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-end mt-5">
      <button
        type="button"
        class="btn btn-secondary btn-lg"
        @click="calcCancel()"
      >
        取消
      </button>
      &nbsp;&nbsp;
      <button type="button" class="btn btn-primary btn-lg" @click="audit()">
        確認發送Ｇ幣
      </button>
    </div>
  </div>

  <!-- 未審核 已審核 -->
  <div v-else>
    <form
      id="filterRow"
      class="row align-items-center py-3"
      @submit.prevent="search()"
    >
      <!-- <div class="col-md-2">
        審核狀態：
        <select class="form-selectx" v-model="monthlyBrokerageFees_Audited">
          <option :value="false">未審核</option>
          <option :value="true">已審核</option>
        </select>
      </div> -->
      <div class="col-md-5">
        <div v-if="monthlyBrokerageFees_Audited === false">
          起始
          <input type="date" v-model="searchDateStart" required />
          &nbsp;&nbsp;-&nbsp;&nbsp; 結束
          <input type="date" v-model="searchDateEnd" required />
        </div>
        <div v-else>
          <month-picker-input
            :lang="`ja`"
            :default-month="date.month"
            :default-year="date.year"
            :input-pre-filled="true"
            @change="showDate"
          >
          </month-picker-input>
        </div>
      </div>

      <div class="col-md-5">
        <button type="submit" class="btn btn-primary">搜尋</button>
        <button
          type="button"
          class="btn btn-danger mx-3 float-end"
          @click="searchCancel()"
          v-if="searched === true"
        >
          取消搜尋 (改顯示當月未審核)
        </button>
      </div>
    </form>
    <hr />

    <div class="row align-items-end mt-5 mb-4">
      <div class="col-md-4">
        <router-link class="btn btn-success mb-3" :to="`/admin/income/insert`">
          新增經紀費
        </router-link>
      </div>
      <div class="col-md-4 text-center">
        <div class="h4">
          <div v-if="searched === true && listType === 'no_audited'">
            {{ searchDateStart }} ~ {{ searchDateEnd }}
          </div>
          <div v-else>
            {{ this.searchingDate.year }} 年 / {{ this.searchingDate.month }} 月
          </div>
          <u class="mt-2 d-inline-block">
            <span v-if="listType === 'no_audited'">未審核</span>
            <span v-else>已審核</span>
          </u>
          資料
        </div>
      </div>
    </div>

    <div class="data_list">
      <div class="table-responsive-lg">
        <div class="verify_btn_wrap">
          <button
            type="button"
            class="btn btn-primary btn-lg shadow verify_btn"
            @click="calc()"
            v-if="checkedMembers.length > 0"
          >
            進行審核試算 <i class="bi bi-caret-right"></i>
          </button>
        </div>

        <!-- 未審核 -->
        <div v-if="listType === 'no_audited'">
          <table class="table table-bordered table-hover">
            <thead class="bg-light">
              <tr>
                <th width="50">
                  <input
                    type="checkbox"
                    v-model="isCheckedMembersAll"
                    @change="CheckedMembersAll()"
                  />
                </th>
                <th width="50">序號</th>
                <th width="100">會員帳號</th>
                <th width="100">會員暱稱</th>
                <th width="150">服務店家</th>
                <th width="150">編號藝名</th>
                <th width="100">經紀費用</th>
                <th width="100">平均台數</th>
                <th width="100">上台數</th>
                <th width="100">上班天數</th>
                <th width="100">薪資金額</th>
                <th width="150">建檔時間</th>
                <th width="150">建檔人員</th>
                <th width="">操作</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="(d, index) in showDatas" :key="index">
                <td>
                  <input
                    type="checkbox"
                    :value="
                      checkboxValSet(
                        d.docId,
                        d.year,
                        d.month,
                        d.memberId,
                        d.member_id,
                        d.no,
                        d.name,
                        d.store,
                        d.numberStageName,
                        d.memberFees,
                        d.hoursOnStage,
                        d.daysAtWork,
                        d.salary,
                        d.role
                      )
                    "
                    v-model="checkedMembers"
                  />
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ d.member_id }}</td>
                <td>{{ d.name }}</td>
                <td>{{ d.store }}</td>
                <td>{{ d.numberStageName }}</td>
                <td>{{ d.memberFees }}</td>
                <td>
                  {{
                    (
                      Math.floor((d.hoursOnStage / d.daysAtWork) * 100) / 100
                    ).toFixed(2)
                  }}
                </td>
                <td>{{ d.hoursOnStage }}</td>
                <td>{{ d.daysAtWork }}</td>
                <td>{{ d.salary }}</td>
                <td>
                  <div v-if="d.entryTime">
                    {{ d.entryTime.toDate().toLocaleString() }}
                  </div>
                </td>
                <td>{{ d.creator }}</td>
                <td>
                  <!-- <button class="btn btn-primary me-5">審核通過</button> -->
                  <router-link
                    :to="`/admin/income/update/${d.docId}/${d.memberId}`"
                    class="btn btn-info text-white mx-1 my-1"
                    v-if="!d.no"
                  >
                    <i class="bi bi-pencil-fill"></i>
                  </router-link>
                  <button
                    class="btn btn-danger mx-1 my-1"
                    @click="deleteFees(`${d.docId}`, `${d.memberId}`)"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 已審核 -->
        <div v-else-if="listType === 'audited'">
          <table class="table table-bordered table-hover">
            <thead class="bg-light">
              <tr>
                <th width="150">會員帳號</th>
                <th width="150">會員暱稱</th>
                <th width="150">服務店家</th>
                <th width="150">經紀費用</th>
                <th width="150">審核人員</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="(d, index) in showDatas" :key="index">
                <td>{{ d.member_id }}</td>
                <td>{{ d.name }}</td>
                <td>{{ d.store }}</td>
                <td>{{ d.memberFees }}</td>
                <td>{{ d.creator }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-end mt-2 mb-2">
      總計人數：{{ count }} &nbsp;&nbsp;&nbsp;&nbsp; 總計金額：{{ total }}
    </div>
  </div>

  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
  />

  <!-- <pre>{{ showDatas }}</pre> -->

  <!-- 1: -->
  <!-- <pre>{{ checkedMembers }}</pre> -->

  <!-- <hr> -->

  <!-- 2: -->
  <!-- <pre>{{ brokerageFeesResultMsg }}</pre> -->

  <!-- <hr> -->

  <!-- 3: -->
  <!-- <pre>{{ brokerageFeesResult }}</pre> -->
</template>

<script>
import { MonthPickerInput } from "vue-month-picker";
import { brokerageFeesService } from "../../../services/brokerageFeesService.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { excelExportService } from "../../../services/excelExportService.js";
import axios from "axios";
export default {
  components: {
    MonthPickerInput,
    Loading,
  },
  data() {
    return {
      brokerageFeesResultMsg: null,
      brokerageFeesResult: null,
      // myModal: null,
      isLoading: false,
      monthlyBrokerageFees_Audited: false,
      listType: "no_audited",
      searchDateStart: "",
      searchDateEnd: "",
      searched: false,
      isCheckedMembersAll: false,
      checkedMembers: [],
      count: 0, // 資料數
      total: 0, // 經紀費總計
      calced: false, // 是否已經試算分潤
      calcedCount: 0,
      calcedTotal: 0,
      allCalcdTotal: {},
      showDatas: {},
      selectedDate: {
        month: null,
        year: null,
      },
      searchingDate: {
        month: null,
        year: null,
      },
      date: {
        from: null,
        to: null,
        month: null,
        year: null,
      },
    };
  },
  methods: {
    onexport() {
      excelExportService.exportBrokerage("calcedTable", "經紀費分潤試算");
    },
    CheckedMembersAll() {
      this.checkedMembers = [];

      if (this.isCheckedMembersAll === true) {
        this.showDatas.forEach((el) => {
          this.checkedMembers.push({
            docId: el.docId,
            year: el.year,
            month: el.month,
            memberId: el.memberId,
            member_id: el.member_id,
            no: el.no,
            name: el.name,
            store: el.store,
            numberStageName: el.numberStageName,
            memberFees: el.memberFees,
            hoursOnStage: el.hoursOnStage,
            daysAtWork: el.daysAtWork,
            salary: el.salary,
            role: el.role,
          });
        });
      }
    },
    checkboxValSet(
      docId,
      year,
      month,
      memberId,
      member_id,
      no,
      name,
      store,
      numberStageName,
      memberFees,
      hoursOnStage,
      daysAtWork,
      salary,
      role
    ) {
      return {
        docId: docId,
        year: year,
        month: month,
        memberId: memberId,
        member_id: member_id,
        no: no,
        name: name,
        store: store,
        numberStageName: numberStageName,
        memberFees: memberFees,
        hoursOnStage: hoursOnStage,
        daysAtWork: daysAtWork,
        salary: salary,
        role: role,
        // memberData: memberData,
      };
    },
    showDate(date) {
      this.selectedDate.month = date.monthIndex;
      this.selectedDate.year = date.year;
    },
    async showByYearMonth(year, month, audited) {
      this.isLoading = true;

      this.checkedMembers = [];
      this.showDatas = [];
      this.selectedDate.month = this.searchingDate.month = month;
      this.selectedDate.year = this.searchingDate.year = year;
      const result = await brokerageFeesService.getByYearMonth(
        year,
        month,
        audited
      );

      this.showDatas = result.data;
      this.count = result.count;
      this.total = result.total;

      this.isCheckedMembersAll = false;
      this.isLoading = false;
    },
    async search() {
      this.isLoading = true;
      this.checkedMembers = [];
      this.showDatas = [];

      const result = await brokerageFeesService.getBySearch(
        this.searchDateStart,
        this.searchDateEnd,
        this.selectedDate,
        this.monthlyBrokerageFees_Audited
      );

      if (this.monthlyBrokerageFees_Audited === true) {
        this.listType = "audited";
      } else {
        this.listType = "no_audited";
      }

      this.showDatas = result.data;
      this.count = result.count;
      this.total = result.total;

      this.searchingDate.month = this.selectedDate.month;
      this.searchingDate.year = this.selectedDate.year;

      this.isCheckedMembersAll = false;
      this.isLoading = false;
      this.searched = true;
    },
    async searchCancel() {
      this.checkedMembers = [];

      this.listType = "no_audited";

      this.searched = false;
      this.isCheckedMembersAll = false;
      this.monthlyBrokerageFees_Audited = false;

      const now = new Date();
      this.date.month = this.selectedDate.month = now.getMonth() + 1;
      this.date.year = this.selectedDate.year = now.getFullYear();
      this.showByYearMonth(this.date.year, this.date.month, false);
    },
    async calc() {
      //開始loading
      this.isLoading = true;

      const startTime = performance.now();

      const result = await brokerageFeesService.calcTriggle(
        this.checkedMembers
      );

      if (result.result === 1) {
        this.brokerageFeesResultMsg = result.brokerageFeesResultMsg;
        this.brokerageFeesResult = result.brokerageFeesResult;
        this.calcedTotal = result.allSetBonus;
        this.calcedCount = result.brokerageFeesResultMsgCount;
        this.allCalcdTotal = result.allCalcdTotal;
        this.checkedMembers = result.checkedMembers;
      }

      const endTime = performance.now();

      console.log(
        `Call to doSomething took ${endTime - startTime} milliseconds`
      );

      console.log(
        "---------------------------------------------------------------------------"
      );

      //結束loading
      this.isLoading = false;

      // 已經試算結束，顯示試算結果
      this.calced = true;
    },
    calcCancel() {
      this.brokerageFeesResultMsg = null;
      this.brokerageFeesResult = null;
      this.calced = false;
      this.checkedMembers = [];

      // 暫時先這樣寫 (重新抓取)，試算之後會產生no編號，需要重新抓才可以避免下次又試算時又產生新的no編號
      // 重新抓取才能讓showDatas有no編號的值，這樣calc時才不會又賦予新的no編號
      // 之後有機會再重構成不用重新抓showDatas，看能不能用變數merge的方法
      const now = new Date();
      this.date.month = this.selectedDate.month = now.getMonth() + 1;
      this.date.year = this.selectedDate.year = now.getFullYear();
      this.showByYearMonth(this.date.year, this.date.month, false);
    },
    async audit() {
      //開始loading
      this.isLoading = true;

      const startTime = performance.now();

      const result = await brokerageFeesService.audit(this.checkedMembers);
      console.log(result);

      // 分潤完後推播
      let content = "分潤G幣";
      let type = "in";
      let brokerageFeesResultMsgObject = Object.values(
        this.brokerageFeesResultMsg
      ); //  定義所有未審核經紀費資料

      //  批次推播所有未審核經紀費資料
      for (
        let brokerageFeesIndex = 0;
        brokerageFeesIndex < brokerageFeesResultMsgObject.length;
        brokerageFeesIndex++
      ) {
        let BrokerageFeeOrder =
          brokerageFeesResultMsgObject[brokerageFeesIndex];
        for (let userIndex = 1; userIndex < 4; userIndex++) {
          let uplineUser = BrokerageFeeOrder["top" + userIndex];

          // 上線存在才推播
          if (
            uplineUser !== undefined &&
            Object.keys(uplineUser).length !== 0 &&
            Object.getPrototypeOf(uplineUser) === Object.prototype
          ) {
            let memberId = uplineUser.memberDocumentId;
            let amount = uplineUser.setBonus;
            await this.pushNotification(memberId, content, amount, type);
          }
        }
      }

      if (result) {
        // 返回沒有選取的會員
        this.showDatas = this.showDatas.filter((e) => {
          return !result.includes(e.memberId + "|" + e.docId);
        });
      }

      const endTime = performance.now();

      console.log(
        `Call to doSomething took ${endTime - startTime} milliseconds`
      );

      // 將選取的會員清空
      this.checkedMembers = [];

      // 全選取消
      this.isCheckedMembersAll = false;

      // 試算結束
      this.calced = false;

      //結束loading
      this.isLoading = false;

      // 時間急迫先暫時這樣寫，因為目前審核完之後，未審核的總計人數與總計金額未重新計算，會導致顯示之前的數字，先用重新整理來解決
      location.reload();
    },
    async deleteFees(docId, memberId) {
      if (confirm("請問您確定要刪除這位會員的經濟費嗎?")) {
        const result = await brokerageFeesService.deleteOneMemberFees(
          docId,
          memberId
        );

        if (result) {
          this.showDatas = this.showDatas.filter((el) => {
            // 返回 ”非“ memberId等於被刪除的 與 docId等於被刪除 的其他資料
            return !(el.memberId === memberId && el.docId === docId);
          });
        } else {
          alert("刪除失敗");
        }
      }
    },
    async pushNotification(memberId, content, amount, type) {
      let pushResult = await axios
        .post(
          "https://us-central1-gx-economic-adviser.cloudfunctions.net/feeSplitting",
          {
            memberId: memberId,
            content: content,
            amount: amount,
            type: type,
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      return pushResult;
    },
  },
  async created() {
    const now = new Date();
    this.date.month = this.selectedDate.month = now.getMonth() + 1;
    this.date.year = this.selectedDate.year = now.getFullYear();

    // 一開始載入本月份未審核的全部資料
    this.showByYearMonth(this.date.year, this.date.month, false);
  },
};
</script>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

#filterRow select {
  height: 49px;
  outline: none;

  box-shadow: none;
  border-color: none;
}

.month-picker-input-container {
  z-index: 1;
}

.data_list {
  position: relative;
}

.verify_btn_wrap {
  position: relative;
  margin-bottom: 60px;
  height: 1px;
}

.verify_btn {
  position: absolute;
  top: 0;
  right: 0;
}

#calcedTable .ws {
  min-width: 100px;
}

#calcedTable .wm {
  min-width: 135px;
}

#calcedTable .wl {
  min-width: 170px;
}

#calcedTable thead {
  font-weight: bold;
  font-size: 18px;
}

#calcedTable .badge.bg-warning,
#calcedTable .badge.bg-info {
  font-size: 0.95em;
}
</style>
